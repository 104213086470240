.microteam-list i {
    display: none;
    font-size: 12px;
    cursor: pointer;
    color: #555;
}

.microteam-list:hover i {
    display: block;
}

.microteam-list:hover {
    background-color: #eee;
}