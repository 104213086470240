.status-button {
    background: transparent !important;
    border-radius: 10 !important;
    font-size: 12 !important;
    height: 20px !important;
}

.status-button .label-110 {
    font-weight: initial!important;
}

.status-button.hideIcon i {
    display: none !important;
}

.status-button:hover {
    background: transparent !important;
}

.status-button:focus {
    background: transparent !important;
}


/* Colors */
.status-circle { font-size: 12px!important; }
.status-All-Status::before { content: none!important}
.status-bg-All-Status::before { content: none!important}

/* Colors - TRM */
.status-Open { color: orange; }
.status-Closed { color: mediumvioletred; }
.status-Pending { color: rgb(89, 172, 87); }
.status-Heads-Up { color: rgb(16, 110, 190) }

.status-bg-Open { background-color: orange; }
.status-bg-Closed { background-color: mediumvioletred; }
.status-bg-Pending { background-color: rgb(89, 172, 87); }
.status-bg-Heads-Up { background-color: rgb(16, 110, 190); }

/* Colors - AB */
.status-Not-Covered { color: mediumorchid; }
.status-Covered { color: lightblue; }
.status-Canceled { color: lightcoral; }
.status-Start { color: lightslategrey; }
.status-Direct-Fill { color: lightseagreen; }
.status-Offer-Accepted { color: lightgreen; }
.status-Send-Out { color: lightpink; }
.status-Opportunity { color: lightgray; }
.status-Feedback { color: mediumturquoise; }
.status-Needs-Allocation { color: mediumpurple; }

.status-bg-Not-Covered { background-color: mediumorchid; }
.status-bg-Covered { background-color: lightblue; }
.status-bg-Canceled { background-color: lightcoral; }
.status-bg-Start { background-color: lightslategrey; }
.status-bg-Direct-Fill { background-color: lightseagreen; }
.status-bg-Offer-Accepted { background-color: lightgreen; }
.status-bg-Send-Out { background-color: lightpink; }
.status-bg-Opportunity { background-color: lightgray; }
.status-bg-Feedback { background-color: mediumturquoise; }
.status-bg-Needs-Allocation { background-color: mediumpurple; }

.option-button {
    outline: transparent;
    position: relative;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 400;
    color: rgb(50, 49, 48);
    background-color: transparent;
    border: none;
    width: 100%;
    height: 36px;
    line-height: 36px;
    display: block;
    cursor: pointer;
    padding: 0px 8px 0px 15px;
    text-align: left;
}
.option-button:hover {
    background-color: rgb(243, 242, 241);
    color: rgb(32, 31, 30);
}