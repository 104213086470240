.App {
    text-align: center;
}

body {
    overflow-x: hidden;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.rounded-top-left {
    border-top-left-radius: 10px;
}

.ag-body-viewport {
    overflow-y: scroll !important;
}

::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #777;
}


.scrollbar-v::-webkit-scrollbar {
    height: 12px;
    /* height of horizontal scrollbar ← You're missing this */
    width: 12px;
    /* width of vertical scrollbar */
    border: 1px solid #d5d5d5;
    z-index: 2000;
}


.form-control:focus {
    box-shadow: none !important;
}


.border-0 .ms-SearchBox {
    border-color: #ddd !important;
}

.border-0 .ms-Dropdown:focus::after {
    border: none !important;
}

.border-0 .ms-Dropdown-title {
    border-color: transparent !important;
}

.h-100>.ag-react-container {
    height: 100%;
}


/* ================== */
/*        GRID        */
/* ================== */
:root {
    --border-top-sm: 0px 7px 2px;
    --border-bottom-sm: 0px -7px 2px;
    --border-top-md: 0px 14px 2px;
    --border-bottom-md: 0px -14px 2px;

    --is-exclusive-color: rgb(226, 255, 230);
    --is-exclusive-color-hover: rgb(212, 245, 217);
    --is-exclusive-border: var(--border-top-sm) var(--is-exclusive-color) inset, var(--border-bottom-sm) var(--is-exclusive-color) inset;
    --is-exclusive-border-hover: var(--border-top-sm) var(--is-exclusive-color-hover) inset, var(--border-bottom-sm) var(--is-exclusive-color-hover) inset;

    --is-hotlist-color: rgb(255, 237, 231);
    --is-hotlist-color-hover: rgb(241, 222, 216);
    --is-hotlist-border: var(--border-top-sm) var(--is-hotlist-color) inset, var(--border-bottom-sm) var(--is-hotlist-color) inset;
    --is-hotlist-border-hover: var(--border-top-sm) var(--is-hotlist-color-hover) inset, var(--border-bottom-sm) var(--is-hotlist-color-hover) inset;

    --clicked-row-color: #fff8e4;
    --clicked-row-color-hover: #f5edd8;
    --clicked-row-border: 0px 1px 2px grey inset, 0px -1px 2px grey inset;
}


.is-exclusive {
    background-color: var(--is-exclusive-color) !important;
}

.is-exclusive:hover {
    background-color: var(--is-exclusive-color-hover) !important;
}


.is-hotlist {
    background-color: var(--is-hotlist-color) !important;
}

.is-hotlist:hover {
    background-color: var(--is-hotlist-color-hover) !important;
}


.is-exclusive.is-hotlist {
    background-color: var(--is-exclusive-color) !important;
    box-shadow: var(--is-hotlist-border) !important;
}

.is-exclusive.is-hotlist:hover {
    background-color: var(--is-exclusive-color-hover) !important;
    box-shadow: var(--is-hotlist-border-hover) !important;
}


.clicked-row {
    background-color: var(--clicked-row-color) !important;
    box-shadow: var(--clicked-row-border) !important;
}

.clicked-row:hover {
    background-color: var(--clicked-row-color-hover) !important;
    box-shadow: var(--clicked-row-border) !important;
}


.is-exclusive.clicked-row {
    background-color: var(--clicked-row-color) !important;
    box-shadow: var(--clicked-row-border), var(--is-exclusive-border) !important;
}

.is-exclusive.clicked-row:hover {
    background-color: var(--clicked-row-color-hover) !important;
    box-shadow: var(--clicked-row-border), var(--is-exclusive-border-hover) !important;
}


.is-hotlist.clicked-row {
    background-color: var(--clicked-row-color) !important;
    box-shadow: var(--clicked-row-border), var(--is-hotlist-border) !important;
}

.is-hotlist.clicked-row:hover {
    background-color: var(--clicked-row-color-hover) !important;
    box-shadow: var(--clicked-row-border), var(--is-hotlist-border-hover) !important;
}


.is-exclusive.is-hotlist.clicked-row {
    background-color: var(--clicked-row-color) !important;
    box-shadow: var(--clicked-row-border), var(--is-hotlist-border), var(--border-top-md) var(--is-exclusive-color) inset, var(--border-bottom-md) var(--is-exclusive-color) inset !important;
}

.is-exclusive.is-hotlist.clicked-row:hover {
    background-color: var(--clicked-row-color-hover) !important;
    box-shadow: var(--clicked-row-border), var(--is-hotlist-border-hover), var(--border-top-md) var(--is-exclusive-color-hover) inset, var(--border-bottom-md) var(--is-exclusive-color-hover) inset !important;
}