.people-list i {
    display: none;
    font-size: 12px;
    cursor: pointer;
    color: #555;
}

.people-list:hover i {
    display: block;
}

.people-list:hover {
    background-color: #eee;
}

.jo-list .ms-ActivityItem-activityContent .ms-ActivityItem-activityText {
    font-weight: bold;
    font-size: .9rem;
    color: #000;
}