.sidebar-menu {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.sidebar-list {
    overflow-y: auto;
}

.sidebar-list .active-list {
    border-left: 4px solid var(--primary) !important;
    border-right: 1px solid var(--primary) !important;
}

.sidebar-list .list-group-item:hover {
    background-color: #eee !important;
}

.sidebar-options {
    position: fixed;
    left: 51px;
}


.sidebar-list .list-group-item {
    cursor: pointer;
    border: none;
}

.sidebar-list .list-group-item span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.submenu {
    position: relative;
}

/* .submenu::before {
    content: '⮑';
    margin-left: 5px;
    position: absolute;
    color: rgba(50, 50, 50, 0.4);
    z-index: 2;
}
 */
.submenu .submenu-active-list {
    color: var(--primary);
}

.submenu > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
}

.sidebar-list .submenu .list-group-item:hover {
    color: #000;
    background-color: #f8f9fa!important;
}