.bg-orange {
    background: #E18854;
}

.ms-ActivityItem {
    margin-top: 5px!important;
}

.ms-Persona-imageArea {
    width: 30px!important;
    height: 30px!important;
    border: none!important;
}

.ms-Persona-coin {
    margin-left: 5px;
}

.ms-Persona-initials {
    border: 2px solid #FFF !important;
    width: 30px!important;
    height: 30px!important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.action-table .ms-Icon[data-icon-name="ChevronDown"] {
    display: none;
}

.ag-theme-alpine .ag-header {
    background: white !important;
    border-color: #eee !important;
}

.ag-header-cell-text {
    color: #777 !important;
    font-family: sans-serif;
}

.ag-root-wrapper.ag-layout-auto-height {
    border: none!important;
}

@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
.ag-theme-alpine .ag-checkbox-input-wrapper.ag-indeterminate::after {
    content: "\f056" !important;
}
.ag-theme-alpine .ag-checkbox-input-wrapper::after {
   font-family: "FontAwesome";
   content: "\f1db" !important;
   font-size: 20px;
   background: #FFF !important;
   color: #ccc !important;
   border-radius: 100%;
}

.ag-center-cols-container .ag-checkbox-input-wrapper::after{
    visibility: hidden!important;
}

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
    font-family: "FontAwesome";
    content: "\f058" !important;
    font-size: 20px;
    border-radius: 100%;
    background: #FFF !important;
    color: #2196f3 !important;
    visibility: visible!important;
}

.ag-theme-alpine .ag-checkbox .ag-input-wrapper, .ag-theme-alpine .ag-radio-button .ag-input-wrapper {
    outline: none!important;
    background: transparent;
    box-shadow: none !important;
}


.ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected), .ag-theme-alpine .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), .ag-theme-alpine .ag-ltr .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group, .ag-theme-alpine .ag-ltr .ag-cell-range-single-cell, .ag-theme-alpine .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle, .ag-theme-alpine .ag-rtl .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected), .ag-theme-alpine .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), .ag-theme-alpine .ag-rtl .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group, .ag-theme-alpine .ag-rtl .ag-cell-range-single-cell, .ag-theme-alpine .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
    border-color: transparent!important;
}

.ag-theme-alpine .ag-row-selected {
    background-color: #eee !important;
}

.ag-row-hover .ag-checkbox-input-wrapper::after{
    visibility: visible!important;
}
