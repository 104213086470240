.column-options {
    display: flex;
}

.option {
    border-radius: 3px;
    border: 1px solid rgb(255, 255, 255);
    transition: box-shadow 0.5s, border 0.5s, background-color 0.5s;
}

.option:hover {
    cursor: pointer;
}

.option.with-filter {
    border: 1px solid rgb(143, 206, 255);
    background-color: rgb(235, 246, 255);
    box-shadow: 0px 0px 3px rgb(143, 206, 255);
}

.column-filter-container {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 300px;
    max-width: 300px;
    width: 300px;
}

.column-filter-wrapper {
    border: solid 1px gray;
    background-color: white;
    box-shadow: 0px 0px 6px rgb(95, 95, 95);
    border-radius: 3px;
    z-index: 100;
    padding: 10px;
}

.close-button-wrapper {
    display: inline-flex;
    align-self: end;
}

.close-button {
    border-radius: 2;
    justify-content: center;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    background-color: red;
    color: white;
    font-weight: 600;
    border: none;
    margin-right: 5;
}

.column-filter-items {
    display: flex;
    flex-direction: column;
}

.column-filter-items div {
    margin-bottom: 4px;
}

.movable {
    cursor: grab;
}