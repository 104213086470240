.recruiter-list i {
    display: none;
    font-size: 12px;
    cursor: pointer;
    color: #555;
}

.recruiter-list:hover i {
    display: block;
}

.recruiter-list:hover {
    background-color: #eee;
}

/* .ms-ActivityItem-activityTypeIcon {
    margin-right: 5px;
} */