.pagination {
    margin: 0px;
    padding: 0px;
}

ul.pagination li:not(.previous):not(.next) {
    width: 30px;
    height: 30px;
    margin-left: 1px;
    margin-right: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    align-self: center;
    justify-content: center;
}

ul.pagination .active {
    border-bottom: 2px solid rgb(0, 120, 212);
}

.paginate-options {
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.ag-body-viewport {
    overflow-x: scroll !important;
} 