.optionBox {
    z-index: 3;
    max-height: fit-content;
    overflow-y: auto;
}

.optionBox li {
    list-style: none;
    cursor: pointer;
}

.optionBox li:hover {
    background-color: #eee;
}