

  .box-error-page hr {
    diplay: block;
    border: none;
    border-bottom: 1px dashed #ccc;
  }
  .box-error-page {
    background-color: #fbfbfb;
    border: 1px solid #AAA;
    border-bottom: 1px solid #888;
    border-radius: 3px;
    color: black;
    box-error-page-shadow: 0px 2px 2px #AAA;
    padding: 20px;
  }
  .box-error-page h1, .box-error-page h2 {
    display: block;
    text-align: center;
  }
  .box-error-page h1 {
    color: #666;
    font-weight: normal;
    font-size: 50px;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    line-height:50px
  }
  .box-error-page h2 {
    color: #666;
    font-weight: normal;
    font-size: 1.5em;
  }
  .box-error-page p {
    display: block;
    margin-bottom: 10px;
  }
  .box-error-page ul li {
    margin-bottom: 7px;
  }
  